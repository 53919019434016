var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:_vm.$style.card},[_c('Filters',{class:[_vm.$style.filters, 'pt-8'],on:{"filter_update":_vm.filter_update},scopedSlots:_vm._u([{key:"btn-outer",fn:function(){return [_c('v-btn',{class:[_vm.$style.btnOuter , 'mb-0'],attrs:{"text":""},on:{"click":function($event){_vm.cols_dialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune")]),_c('span',[_vm._v("Настройка полей")])],1)]},proxy:true}])}),_vm._l((_vm.filtered_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{key:("table_" + _vm.table_key),class:_vm.$style.table,attrs:{"headers":_vm.filtered_cols,"items":_vm.frontol_licenses,"options":_vm.table_options,"custom-sort":_vm.prevent_sort,"loading":_vm.frontol_licenses_loading,"no-data-text":"Лицензии, удовлетворяющие выбранным параметрам, отсутствуют","hide-default-footer":""},on:{"update:options":function($event){_vm.table_options=$event},"click:row":_vm.row_click_handler},scopedSlots:_vm._u([{key:"item.license_key",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[(item.license_key)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.license_key)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.license_key)+" ")]),(item.license_key && item.has_detail)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.go_to_open_frontol_license_details(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}}],null,true)},[_c('span',[_vm._v("Посмотреть детализацию по идентификатору")])]):_vm._e()],1)])]}},{key:"item.nomenclature",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[(item.nomenclature)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.nomenclature)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',{class:[_vm.$style.nomenclatureText, _vm.$style.textWrap]},[_vm._v(" "+_vm._s(item.nomenclature)+" ")])],1)])]}},{key:"item.client_date_activation",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(item.client_date_activation))+" ")])])]}},{key:"item.client_date_expired",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(item.client_date_expired))+" ")])])]}},{key:"item.contacts",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('div',[_vm._v(_vm._s(item.client_contact))]),_c('div',{staticClass:"d-flex align-center",class:_vm.$style.is_mobile},[(item.client_phone)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.client_phone)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.client_phone))])],1),_c('div',{staticClass:"d-flex align-center",class:_vm.$style.is_mobile},[(item.client_email)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.client_email)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.client_email))])],1)])]}},{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[_c('span',[_vm._v(" "+_vm._s(item.client_name)+" "+_vm._s(item.client_type)+" ")])])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('ChipStatus',{attrs:{"status":_vm.get_licenses_chip_status(item)}},[_vm._v(" "+_vm._s(item.status.name)+" ")])],1)]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true)})],2),_c('CustomColsDialog',{attrs:{"cols":_vm.cols,"cols_list":_vm.cols,"storage_name":_vm.cols_storage_name,"default_cols":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols=$event},"save_cols":function($event){_vm.cols_dialog = false},"cancel_dialog":function($event){_vm.cols_dialog = false},"close":function($event){_vm.cols_dialog = false},"default_limit":_vm.default_limit},model:{value:(_vm.cols_dialog),callback:function ($$v) {_vm.cols_dialog=$$v},expression:"cols_dialog"}}),_c('SimpleDialog',{attrs:{"center_title":"","center_text":"","max-width":"768","bts_align":"center"},on:{"close":function($event){_vm.is_modal_frontol_order_allowed = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Пока вы не можете осуществлять продажу ПО Frontol ")]},proxy:true},{key:"text",fn:function(){return [_c('div',{staticClass:"pb-8"},[_c('p',[_vm._v("Партнёры, продающие ПО Frontol, за 2023 год заработали более 250 000 000 рублей.")]),_vm._v(" Если хотите начать продавать ПО Frontol, обратитесь к своему менеджеру. ")])]},proxy:true}]),model:{value:(_vm.is_modal_frontol_order_allowed),callback:function ($$v) {_vm.is_modal_frontol_order_allowed=$$v},expression:"is_modal_frontol_order_allowed"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }